import React from "react";
import {AnchorHeaders} from "./AnchorHeaders";
import * as styles from './menus.module.scss';

const links = [
    {
        id: "About",
        label: "はじめに",
    },
];

export const MidTopHeader = () => <AnchorHeaders links={ links } spContainerClassName={styles.midAnchorSpMenu}/>;