import React from "react";
import * as styles from "../../../styles/careers/intern.module.scss";
import { CareerMidLayout } from "../../../organisms/careers/CareerMidLayout";
import * as commonStyles from "../../../styles/common.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { MidTopHeader } from "../../../organisms/menus/MidTopHeader";
import { NewsComponentsCareer } from "../../../organisms/news/NewsComponentsCareer";
import Scroll, { scrollImageColorTypes } from "../../../atoms/newScroll";
import MessageImg2 from "../../../images/career_new_top/_kpo0731.jpg";
import MessageImg1 from "../../../images/career_new_top/_kpo1145_re.jpg";
import ReasonImg2 from "../../../images/career_new_top/_kpo0873.jpg";
import ReasonImg1 from "../../../images/career_new_top/_kpo0813.jpg";
import { SEO } from "../../../atoms/seo/SEO";
import seoImage from "../../../images/career_mid/career_mid_head.jpg";

export default ({ location: { pathname } }) => (
  <CareerMidLayout current="top" extraHeaders={<MidTopHeader />}>
    <SEO
      pathname={pathname}
      title="中途採用TOP | MidCareerTop"
      description="スプリーブホールディングス株式会社の採用ページです。求人情報を記載しております。採用基準は「友達になれそうな人」！元芸人が創った会社が就職企業人気ランキング人材部門3位！未上場成長率1位の注目企業！エントリーはこちらから！"
      image={seoImage}
    />
    <div className={styles.midTopTitle}>
      <h2>Mid-career</h2>
      <h3>キャリア採用</h3>
    </div>

    <a id="About"></a>
    <div className={styles.blog}>
      <div className={styles.blogContainerLast}>
        <div className={styles.blogContainerLeft}>
          <h2>
            <span>New</span>2021.7.10
          </h2>
        </div>
        <div className={styles.blogContainerRight}>
          <h3>22卒まだまだ積極採用中</h3>
        </div>
      </div>
    </div>

    <Scroll type={scrollImageColorTypes.BLACK} />

    <div className={styles.first}>
      <div className={styles.firstText}>
        <div className={styles.slideWrap}>
          <iframe
            className={styles.slide}
            frameBorder="0"
            src="https://speakerdeck.com/player/af54e47d86bf40af89b9cd2c991d4f8a?slide=1"
            title="_23卒_SuprieveHoldings株式会社_説明会資料_最新.pdf"
            data-ratio="1.7777777777777777"
          ></iframe>
        </div>
      </div>
    </div>

    <div className={styles.news}>
      <div className={styles.topTitle}>
        <h2>Suprieve NOW!</h2>
        <h3>Suprieveに関する情報、ニュース、最新動向を随時掲載しています</h3>
      </div>

      <div className={styles.nowNews}>
        <div className={styles.container}>
          <div className={styles.pconly}>
            <NewsComponentsCareer limit={3} />
          </div>
          <div className={styles.sponly}>
            <NewsComponentsCareer limit={6} />
          </div>
          <div className={styles.newsArchive}>
            <div className="flex-1" />
            <Link to="/careers/mid/suprieve-now/">View All</Link>
          </div>
        </div>
      </div>
    </div>

    <div className={styles.reason}>
      <div className={styles.reasonTitle}>
        <h2>Why Suprieve?</h2>
        <h3>Suprieveで働く理由</h3>
      </div>
      <div className={styles.reasonContainer}>
        <img className={styles.reasonImg} src={ReasonImg1} alt="" />
        <div className={styles.reasonContent}>
          <h3>Culture</h3>
          <h4>カルチャー</h4>
          <h2>
            最強のチームから
            <br />
            この上ない最高の世界が始まる。
          </h2>

          <div className={styles.viewAll}>
            <Link to="/company/culture/">View All</Link>
          </div>
        </div>
      </div>
      <div className={styles.reasonContainer}>
        <img className={styles.reasonImg} src={ReasonImg2} alt="" />
        <div className={styles.reasonContent}>
          <h3>Working Environment</h3>
          <h4>働く環境</h4>
          <h2>キャリア開発を支援する仕組み</h2>

          <div className={styles.viewAll}>
            <Link to="/careers/mid/why-suprieve/">View All</Link>
          </div>
        </div>
      </div>
    </div>

    <div className={styles.about}>
      <div className={styles.aboutTitle}>
        <h2>About Suprieve</h2>
        <p>Suprieveについて</p>
      </div>

      <div>
        <div className={styles.messageContainer}>
          <div className="flex-1" />
          <div className={styles.massageContainerLeft}>
            <img className={styles.messageImg} src={MessageImg1} alt="" />
            <h2>CEO Message</h2>
            <h3>代表メッセージ</h3>
            <div className={styles.viewAllGray}>
              <Link to="/company/ceo-message/">View All</Link>
            </div>
          </div>

          <div className={styles.massageContainerRight}>
            <img className={styles.messageImg} src={MessageImg2} alt="" />
            <h2>Company Overview</h2>
            <h3>会社概要・拠点</h3>
            <div className={styles.viewAllGray}>
              <Link to="/company/overview/">View All</Link>
            </div>
          </div>
          <div className="flex-1" />
        </div>
      </div>
    </div>
  </CareerMidLayout>
);
